.form {
  padding: 20px;
  background-color: #f7f8f9;
  border-radius: 0.2rem;
}

.container {
  padding: 0;
}

p {
  color: black;
}

label {
  color: black;
}
