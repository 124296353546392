.footer-dark {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #151212;
  color: white;
}

a.sc-eCAqax.iZztkf {
  color: white !important;
}

.logo {
  width: 200px;
  height: 100px;
}

.sc-jSFipO.jEzpfX.first {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: -20px;
}

.center {
  width: 300px;
  margin-top: -70px;
}

.sc-dlniIP.hKBOKa.news {
  margin-left: 160px;
  width: 500px;
}

p {
  color: #fff;
  margin-bottom: 20px;
  font-size: 16px;
  text-decoration: none;
}

h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 16px;
  text-decoration: none;
}

.copyRight {
  margin-top: 30px;
}

.sc-gtsqUy.fqYvJR {
  margin-left: 30px;
}

.p-inputtext {
  margin: 0;
  width: 300px;
  height: 30px;
  margin-top: -30px;
}

/* CSS */
.button-23 {
  align-items: center;
  appearance: button;
  background-color: #ffffff;
  border-radius: 25px;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color: #ec1f6a;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-family: "RM Neue", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 1px 1px;
  text-align: center;
  text-transform: none;
  transition: color 0.13s ease-in-out, background 0.13s ease-in-out,
    opacity 0.13s ease-in-out, box-shadow 0.13s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  
}

.button-23:active {
  background-color: #ec1f6a;
  color: #fff;
}

.button-23:hover {
  background-color: #ec1f6a;
  color: #fff;
}

/* CSS */
.button-22 {
  align-items: center;
  appearance: button;
  background-color: #ffffff;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color: #ec1f6a;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-family: "RM Neue", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 10px 21px;
  text-align: center;
  text-transform: none;
  transition: color 0.13s ease-in-out, background 0.13s ease-in-out,
    opacity 0.13s ease-in-out, box-shadow 0.13s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  width: 150px;
}

.button-22:active {
  background-color: #ec1f6a;
  color: #fff;
}

.button-22:hover {
  background-color: #ec1f6a;
  color: #fff;
}

img {
  height: 46px;
  float: left;
  margin-top: 10px;
  margin-left: 2px;
}

.bannerAbout {
  float: none !important;
  margin: auto;
  width: 50%;
  margin-bottom: 10px;
}
