.header {
  position: absolute;
  top: 0;
  left: 0;
  height: 130px;
  width: 100%;
  text-align: center;
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/images/bg1.jpg") no-repeat;
  background-size: cover;
  /* or "contain" depending on what you want */
  background-position: center center;
  background-repeat: no-repeat;
  background: "rgba(255,255,255,0.7)";
  color: "black";
}

.p-dropdown-trigger {
  width: 20px;
}

.logoHeader {
  height: 50px;
  float: left;
  margin-left: 40px;
  margin-top: 20px;
  margin-right: 180px;
}

/* CSS */
.button-24 {
  align-items: center;
  appearance: button;
  background-color: #ec1f6a;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color:  #ffffff;;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-family: "RM Neue", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 10px 21px;
  text-align: center;
  text-transform: none;
  transition: color 0.13s ease-in-out, background 0.13s ease-in-out,
    opacity 0.13s ease-in-out, box-shadow 0.13s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  float: right;
  margin-right: 20px;
  margin-top: 20px !important;
}

.button-24:active {
  background-color: #fff;
  color: #ec1f6a;
}

.button-24:hover {
  background-color: #fff;
  color: #ec1f6a;
}

/* CSS */
.button-22 {
  align-items: center;
  appearance: button;
  background-color: #ffffff;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color: #ec1f6a;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-family: "RM Neue", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 10px 21px;
  text-align: center;
  text-transform: none;
  transition: color 0.13s ease-in-out, background 0.13s ease-in-out,
    opacity 0.13s ease-in-out, box-shadow 0.13s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  float: right;
  margin-right: 20px;
  margin-top: 20px !important;
}

.button-22:active {
  background-color: #ec1f6a;
  color: #fff;
}

.button-22:hover {
  background-color: #ec1f6a;
  color: #fff;
}

.car {
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: #fff;
  border-radius: 20px;
  margin-left: 25px;
}

.carDiv {
  position: relative;
  top: 20px;
  margin-left: 20px;
}

.carDiv:hover image {
  background-color: #ec1f6a;
}

.carDiv p {
  margin-top: -1px;
  margin-left: 25px;
}

.p-inputtext {
  margin: 0;
  width: 300px;
  height: 30px;
  margin-top: -5px !important;
}
