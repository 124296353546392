html {
  font-size: 14px;
}

.footer-banner {
  width: 100%;
  aspect-ratio: 7/1;
  margin-top: -60px;
  margin-left: 10%;
}
.footer-banner img {
  width: 80%;
  height: 70%;
  object-fit: cover;
  align-self: center;
}

body {
  font-family: var(--font-family);
  font-weight: normal;
  background-color: white;
  color: var(--text-color);
  padding: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  background-color: transparent;
}

.w-full {
  width: 70% !important;
}

.container {
  margin-top: 150px !important;
}

.react-slideshow-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 260px !important;
}

.react-slideshow-wrapper .images-wrap.horizontal {
  display: flex;
  flex-wrap: wrap;
  height: 200px !important;
}

#chatCon {
  width: 20%;
  position: absolute;
  bottom: 6%;
  right: 3%;
}

#chatCon .chat-box {
  /* display: none; */
  width: 100%;
  height: 500px;
  border-radius: 25px;
  background-color: #eee;
}

#chatCon .chat-box .header {
  background-color: black;
  padding: 15px;
  border-radius: 20px 20px 0 0;
  color: #fff;
  font-size: 20px;
}

#chatCon .chat-box .msg-area {
  overflow: hidden;
  height: 370px;
  padding: 15px;
}

#chatCon .chat-box .msg-area .left span {
  display: inline-block;
  font-size: 17.5px;
  border-radius: 15px;
  padding: 15px;
  background-color: #ddd;
}

#chatCon .chat-box .msg-area .right {
  text-align: right;
}

#chatCon .chat-box .msg-area .right span {
  display: inline-block;
  font-size: 17.5px;
  border-radius: 15px;
  padding: 15px;
  background-color: #ddd;
}

#chatCon .chat-box .footer {
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 59px;
}

#chatCon .chat-box .footer input {
  border: 1px solid #fff;
  padding: 10px;
  width: 80%;
  border-radius: 15px;
}

#chatCon .chat-box .footer input:foucs {
  outline: none;
}

#chatCon .chat-box .footer button {
  border: none;
  font-size: 22.5px;
  color: black;
  cursor: pointer;
}

#chatCon .pop {
  width: 100%;
  height: 25%;
  cursor: pointer;
}

#chatCon .pop p {
  text-align: right;
}

#chatCon .pop img {
  width: 25%;
}
