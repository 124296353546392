.results-list {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: auto;
    color:black;
    text-align: left;
    position: relative;
    z-index: 100;
  }