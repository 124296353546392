.main {
  position: absolute;
  top: 126px;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  background-color: white;
}

.footer-banner {
  width: 100%;
  aspect-ratio: 7/1;
  margin-top: -60px;
  margin-left: 10%;
}
.footer-banner img {
  width: 80%;
  height: 70%;
  object-fit: cover;
  align-self: center;
}
.slick-slider {
  position: relative;
  display: block;
  width: 90%;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  margin-left: 40px;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 5;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #ec1f6a !important;
  border: none;
  outline: none;
  background: grey !important;
}

.react-slideshow-wrapper.slide {
  width: 90% !important;
  overflow: hidden;
  margin: auto;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

body {
  font-family: var(--font-family);
  font-weight: normal;
  background: var(--surface-ground);
  color: var(--text-color);
  padding: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  top: -11px;
  width: 100%;
  margin: auto;
  position: relative;
}

h4 {
  color: black;
  margin-top: 217px;
}

h6 {
  color: black;
}

span.p-tag-value {
  color: grey;
  font-weight: 500;
}

svg {
  width: 10px;
  height: 10px;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;

  background-image: none;
  background-image: none;
  color: black;
}

.w-20 {
  width: 550px;
}

.h-20 {
  height: 200px;
}

.p-carousel[pr_id_2] .p-carousel-item {
  flex: 1 0 3;
}

.car {
  width: 60px;
  height: 60px;
  padding: 5px;
  background-color: #fff;
  margin-left: 25px;
}

.carDiv p {
  margin-top: 1px;
  margin-left: 25px;
  color: black;
  font-size: 13px;
}

.p-virtualscroller.p-virtualscroller-horizontal.p-horizontal-scroll {
  height: 90px !important;
  /* position: absolute; */
  /* left: 50%; */
  margin: auto;
  width: 58% !important;
  /* border: 3px solid green; */
  padding: 10px;
}

.flex {
  display: flex !important;
  /* align-items: center; */
  /*justify-content: center !important;*/
}

.carDiv:hover image {
  background-color: #ec1f6a;
}

.carDiv p {
  margin-top: -10px;
  margin-left: 25px;
}

.test {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  margin: 0.5rem !important;
  padding-top: 0rem !important;
  padding-bottom: 2rem !important;
}
